<template>
  <div class="vue-tempalte">
    <h2>{{ $t("review.no_review") }}</h2>
  </div>
</template>


<script>
import {socialvue} from '@/config/pluginInit'

export default {
  name: 'MyReviewsPage',
  mounted() {
    socialvue.index()
  },
  data() {
    return {}
  },
  created() {
    // reset login status
  },
  methods: {}
};
</script>

<style scoped>

</style>